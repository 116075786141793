<template>
  <div id="issueAnnounceDialog" ref="issueAnnounceDialog"  >
      <a-modal v-model="visible"
              :title="mtitle" 
              :width="1280"
			  @ok="handleOk"
              :getContainer="() => this.$refs.issueAnnounceDialog"     
      >
          <div v-loading="loading" :element-loading-text="$t('alarm.a0')">
                <div class="base-box">
                    <a-row>
                        <a-col :span="20">
                           <div class="bgm">
                               <div class="logo">
                                    <img src="../../../public/images/announcement/logo.png" alt="HITACHI" style="width:100%;">
                                </div>
                                <div :class="vail.pln.cls">
									<a-tooltip placement="bottomLeft"  :title="vail.pln.title"  :visible="vail.pln.visible" :getPopupContainer="() => this.$refs.issueAnnounceDialog"     >
										<a-input class="txt2" v-model="form.placeName" :style="clr1"></a-input>
									</a-tooltip>
                                </div>
                                <div :class="vail.slg.cls">
                                    <a-tooltip placement="bottomLeft"  :title="vail.slg.title"  :visible="vail.slg.visible" :getPopupContainer="() => this.$refs.issueAnnounceDialog"     >
										<a-input class="txt5" v-model="form.sologon" :style="clr2"></a-input>
									</a-tooltip>
                                </div>
                                <div :class="vail.clmk1.cls">
                                    <a-tooltip placement="bottomLeft"  :title="vail.clmk1.title"  :visible="vail.clmk1.visible" :getPopupContainer="() => this.$refs.issueAnnounceDialog"     >
										<a-input class="txt1" v-model="form.clmn1Title" :style="clr3"></a-input>
									</a-tooltip>
                                </div>
                                <div :class="vail.clmv1.cls">
                                    <a-tooltip placement="bottomLeft"  :title="vail.clmv1.title"  :visible="vail.clmv1.visible" :getPopupContainer="() => this.$refs.issueAnnounceDialog"     >
										<a-input class="txt2" v-model="form.clmn1Value" :style="clr4"></a-input>
									</a-tooltip>
                                </div>
                                <div :class="vail.clmk2.cls">
                                    <a-tooltip placement="bottomLeft"  :title="vail.clmk2.title"  :visible="vail.clmk2.visible" :getPopupContainer="() => this.$refs.issueAnnounceDialog"     >
										<a-input class="txt1" v-model="form.clmn2Title" :style="clr3"></a-input>
									</a-tooltip>
                                </div>
                                <div :class="vail.clmv2.cls">
                                    <!-- <a-date-picker class="txt3" :style="clr4"></a-date-picker>
                                    &nbsp;~&nbsp;
                                    <a-date-picker class="txt3" :style="clr4"></a-date-picker> -->
									<a-tooltip placement="bottomLeft"  :title="vail.clmv2.title"  :visible="vail.clmv2.visible" :getPopupContainer="() => this.$refs.issueAnnounceDialog"     >
										<a-range-picker v-model="form.date"></a-range-picker>
									</a-tooltip>
                                </div>
                                
                                <div :class="vail.clmk3.cls">
                                   <a-tooltip placement="bottomLeft"  :title="vail.clmk3.title"  :visible="vail.clmk3.visible" :getPopupContainer="() => this.$refs.issueAnnounceDialog"     >
										<a-input class="txt1" v-model="form.clmn3Title" :style="clr3"></a-input>
									</a-tooltip>
                                </div>
                                <div :class="vail.clmv3.cls">
                                   <a-tooltip placement="bottomLeft"  :title="vail.clmv3.title"  :visible="vail.clmv3.visible" :getPopupContainer="() => this.$refs.issueAnnounceDialog"     >
										<a-input class="txt2" v-model="form.clmn3Value" :style="clr4"></a-input>
									</a-tooltip>
                                </div>
                                <div :class="vail.clmk4.cls">
                                   <a-tooltip placement="bottomLeft"  :title="vail.clmk4.title"  :visible="vail.clmk4.visible" :getPopupContainer="() => this.$refs.issueAnnounceDialog"     >
										<a-input class="txt1" v-model="form.clmn4Title" :style="clr3"></a-input>
									</a-tooltip>
                                </div>
                                <div :class="vail.clmv4.cls">
                                   <a-tooltip placement="bottomLeft"  :title="vail.clmv4.title"  :visible="vail.clmv4.visible" :getPopupContainer="() => this.$refs.issueAnnounceDialog"     >
										<a-input class="txt2" v-model="form.clmn4Value" :style="clr4"></a-input>
									</a-tooltip>
                                </div>
                                
                                
                                <div :class="vail.clmk5.cls">
                                    <a-tooltip placement="bottomLeft"  :title="vail.clmk5.title"  :visible="vail.clmk5.visible" :getPopupContainer="() => this.$refs.issueAnnounceDialog"     >
										<a-input class="txt1" v-model="form.clmn5Title" :style="clr3"></a-input>
									</a-tooltip>
                                </div>
                                <div :class="vail.clmv5.cls">
                                   <a-tooltip placement="bottomLeft"  :title="vail.clmv5.title"  :visible="vail.clmv5.visible" :getPopupContainer="() => this.$refs.issueAnnounceDialog"     >
										<a-input class="txt2" v-model="form.clmn5Value" :style="clr4"></a-input>
									</a-tooltip>
                                </div>
                                <div :class="vail.clmk6.cls">
                                    <a-tooltip placement="bottomLeft"  :title="vail.clmk6.title"  :visible="vail.clmk6.visible" :getPopupContainer="() => this.$refs.issueAnnounceDialog"     >
										<a-input class="txt1" v-model="form.clmn6Title" :style="clr3"></a-input>
									</a-tooltip>
                                </div>
                                <div :class="vail.clmv6.cls">
                                    <a-tooltip placement="bottomLeft"  :title="vail.clmv6.title"  :visible="vail.clmv6.visible" :getPopupContainer="() => this.$refs.issueAnnounceDialog"     >
										<a-input class="txt2" v-model="form.clmn6Value" :style="clr4"></a-input>
									</a-tooltip>
                                </div>
                                
                                
                                <div :class="vail.alm.cls">
                                    <a-tooltip placement="right"  :title="vail.alm.title"  :visible="vail.alm.visible" :getPopupContainer="() => this.$refs.issueAnnounceDialog"     >
										<a-input class="txt4" v-model="form.alarmText" :style="clr5"></a-input>
									</a-tooltip>
                                </div>
                                <div :class="vail.mem.cls">
                                   <a-tooltip placement="bottomLeft"  :title="vail.mem.title"  :visible="vail.mem.visible" :getPopupContainer="() => this.$refs.issueAnnounceDialog"     >
									 	<a-input class="txt2" v-model="form.memo" :style="clr6"></a-input>
									</a-tooltip>
                                </div>
                                <div class="date">
									<a-input class="txt2" v-model="form.now" :readOnly="true" :style="clr6"></a-input>
                                </div>
                           </div>
                        </a-col>
                        <a-col :span="4">
						
							<div class="set1">
								<div style="">
									<strong>
										<!-- 标题字体设置 -->
										{{$t('energy.enedata403')}}：	
									</strong>
								</div>
								<div class="set2">
									<div class="sq">
										<!-- 大小： -->
										
										{{$t('energy.enedata410')}}：
										<a-select style="width: 80px;" v-model="form.fontSize1" @change="changeFontSize(1)">
											<a-select-option v-for="i in 20" :key="(8+i*2)">
												{{ (8+i*2) + 'px' }}
											</a-select-option>
										</a-select>
									</div>
									<div>
										<!-- 颜色： -->
										{{$t('energy.enedata411')}}：
										<a-tooltip placement="bottomLeft"  :title="vail.color1.title"  :visible="vail.color1.visible" :getPopupContainer="() => this.$refs.issueAnnounceDialog" >
											<a-input v-model="form.color1" :title="form.color1" :class="vail.color1.cls" style="width: 80px;padding:4px;" @change="changeColor(1)"></a-input>
										</a-tooltip>
										<a-divider type="vertical" />										
										<a-input type="color" v-model="form.color1" @change="changeColor(1)" style="width: 50px;padding:4px;cursor: pointer;" :title="$t('energy.enedata431')"></a-input>
			            			</div>
								</div>
							</div>
							<div class="set1">
								<div style="">
									<strong>
										<!-- 副标字体设置 -->
										{{$t('energy.enedata404')}}：	
									</strong>
								</div>
								<div class="set2">
									<div class="sq">
										<!-- 大小： -->										
										{{$t('energy.enedata410')}}：
										<a-select style="width: 80px;" v-model="form.fontSize2" @change="changeFontSize(2)">
											<a-select-option v-for="i in 20" :key="(8+i*2)">
												{{ (8+i*2) + 'px' }}
											</a-select-option>
										</a-select>
									</div>
									<div>
										<!-- 颜色： -->
										{{$t('energy.enedata411')}}：
										<a-tooltip placement="bottomLeft"  :title="vail.color2.title"  :visible="vail.color2.visible" :getPopupContainer="() => this.$refs.issueAnnounceDialog" >
											<a-input v-model="form.color2" :class="vail.color2.cls" style="width: 80px;padding:4px;" @change="changeColor(2)"></a-input>
										</a-tooltip>
										<a-divider type="vertical" />
										<a-input type="color" v-model="form.color2" @change="changeColor(2)" style="width: 50px;padding:4px;cursor: pointer;" :title="$t('energy.enedata431')"></a-input>
			            			</div>
								</div>
							</div>
							<div class="set1">
								<div style="">
									<strong>
										<!-- 标签项标题字体设置 -->
										{{$t('energy.enedata405')}}：	
									</strong>
								</div>
								<div class="set2">	
									<div class="sq">
										<!-- 大小： -->
										{{$t('energy.enedata410')}}：
										<a-select style="width: 80px;" v-model="form.fontSize3" @change="changeFontSize(3)">
											<a-select-option v-for="i in 20" :key="(8+i*2)">
												{{ (8+i*2) + 'px' }}
											</a-select-option>
										</a-select>
									</div>
									<div>
										<!-- 颜色： -->
										{{$t('energy.enedata411')}}：
										<a-tooltip placement="bottomLeft"  :title="vail.color3.title"  :visible="vail.color3.visible" :getPopupContainer="() => this.$refs.issueAnnounceDialog" >
											<a-input v-model="form.color3" :class="vail.color3.cls" style="width: 80px;padding:4px;" @change="changeColor(3)"></a-input>
										</a-tooltip>
										<a-divider type="vertical" />
										<a-input type="color" v-model="form.color3" @change="changeColor(3)" style="width: 50px;padding:4px;cursor: pointer;" :title="$t('energy.enedata431')"></a-input>
			            			</div>
								</div>
							</div>
							<div class="set1">
								<div style="">
									<strong>
										<!-- 标签项内容字体设置 -->
										{{$t('energy.enedata406')}}：	
									</strong>
								</div>
								<div class="set2">
									<div class="sq">
										<!-- 大小： -->
										{{$t('energy.enedata410')}}：
										<a-select style="width: 80px;" v-model="form.fontSize4" @change="changeFontSize(4)">
											<a-select-option v-for="i in 20" :key="(8+i*2)">
												{{ (8+i*2) + 'px' }}
											</a-select-option>
										</a-select>
									</div>
									<div>
										<!-- 颜色： -->
										{{$t('energy.enedata411')}}：
										<a-tooltip placement="bottomLeft"  :title="vail.color4.title"  :visible="vail.color4.visible" :getPopupContainer="() => this.$refs.issueAnnounceDialog" >
											<a-input v-model="form.color4" :class="vail.color4.cls" style="width: 80px;padding:4px;" @change="changeColor(4)"></a-input>
										</a-tooltip>
										<a-divider type="vertical" />
										<a-input type="color" v-model="form.color4" @change="changeColor(4)" style="width: 50px;padding:4px;cursor: pointer;" :title="$t('energy.enedata431')"></a-input>
			            			</div>
								</div>
							</div>
							<div class="set1">
								<div style="">
									<strong>
										<!-- 警告提示字体设置 -->
										{{$t('energy.enedata407')}}：	
									</strong>
								</div>
								<div class="set2">
									<div class="sq">
										<!-- 大小： -->
										{{$t('energy.enedata410')}}：
										<a-select style="width: 80px;" v-model="form.fontSize5" @change="changeFontSize(5)">
											<a-select-option v-for="i in 20" :key="(8+i*2)">
												{{ (8+i*2) + 'px' }}
											</a-select-option>
										</a-select>
									</div>
									<div>
										<!-- 颜色： -->
										{{$t('energy.enedata411')}}：
										<a-tooltip placement="bottomLeft"  :title="vail.color5.title"  :visible="vail.color5.visible" :getPopupContainer="() => this.$refs.issueAnnounceDialog" >
											<a-input v-model="form.color5" :class="vail.color5.cls" style="width: 80px;padding:4px;" @change="changeColor(5)"></a-input>
										</a-tooltip>
										<a-divider type="vertical" />
										<a-input type="color" v-model="form.color5" @change="changeColor(5)" style="width: 50px;padding:4px;cursor: pointer;" :title="$t('energy.enedata431')"></a-input>
			            			</div>
								</div>
							</div>
							<div class="set1">
								<div style="">
									<strong>
										<!-- 备注字体设置 -->
										{{$t('energy.enedata408')}}：	
									</strong>
								</div>
								<div class="set2">
									<div class="sq">
										<!-- 大小： -->
										{{$t('energy.enedata410')}}：										
										<a-select style="width: 80px;" v-model="form.fontSize6" @change="changeFontSize(6)">
											<a-select-option v-for="i in 20" :key="(8+i*2)">
												{{ (8+i*2) + 'px' }}
											</a-select-option>
										</a-select>
									</div>
									<div>
										<!-- 颜色： -->
										{{$t('energy.enedata411')}}：
										<a-tooltip placement="bottomLeft"  :title="vail.color6.title"  :visible="vail.color6.visible" :getPopupContainer="() => this.$refs.issueAnnounceDialog" >
											<a-input v-model="form.color6" :class="vail.color6.cls" style="width: 80px;padding:4px;" @change="changeColor(6)"></a-input>
										</a-tooltip>
										<a-divider type="vertical" />
										<a-input type="color" v-model="form.color6" @change="changeColor(6)" style="width: 50px;padding:4px;cursor: pointer;" :title="$t('energy.enedata431')"></a-input>
			            			</div>
								</div>
							</div>

							<div class="set">
								<div style="">
									<strong>
										<!-- 是否发送到显示器 -->
										{{$t('energy.enedata409')}}
									</strong>
								</div>
								<div class="set2">
									 <a-radio-group name="radioGroup" v-model="onlySave">
										<a-radio :value="0">
											<!-- 仅保存 -->
											{{$t('energy.enedata412')}}
										</a-radio>
										<a-radio :value="1">
											<!-- 同步到显示器 -->
											{{$t('energy.enedata413')}}
										</a-radio>
									 </a-radio-group>
								</div>
							</div> 
                        </a-col>
                    </a-row>
                </div>
          </div>
		   <template slot="footer">
				<a-button key="issue" type="primary" :loading="loading" @click="handleOk(1)" :title="$t('energy.enedata427')">
					{{$t('energy.enedata402')}}
				</a-button>
				<a-button key="font" type="primary" :loading="loading" @click="handleOk(2)" :title="$t('energy.enedata428')">
					<!-- 设置字体样式 -->
					{{$t('energy.enedata414')}}
				</a-button>
				<a-button key="submit" type="primary" :loading="loading" @click="handleOk(4)" :title="$t('energy.enedata429')">
					<!-- 设置 -->
					{{$t('energy.enedata023')}}
				</a-button>
				<a-button key="back" @click="handleCancel">
					<!-- 关闭 -->
					{{$t('video.viddata054')}}
				</a-button>
			</template>
      </a-modal>
  </div>
</template>
<script>
import { getEnergyDeviceAnnouncementInfo,saveAnnouncementInfo } from "../../api/energy";
import moment from "moment";

export default {
  name: "issueAnnounceDialog",  
  props: {
    title: {
      type: String,
      default: '发布信息',
	},
	equipmentId: {
      type: Number,
      default:0,
    },
    id: {
      type: Number,
      default:0,
    },
    messageType: {
      type: Number,
      default:0,
    }
  },
  data() {
    return {
		mtitle:this.$t('energy.enedata402'),
		mode:4,
		onlySave:1,
        loading : false,
		visible : false,
		disable:false,
        disallowed:this.$t('energy.setting.reportGr.stop')+'[`~!@#$%^ &amp; *()_+<>?: " { } , . \ / ;  [ \ ] ] ·！#￥（——）：；“”‘、，|《。》？、【】',
		form:{
			announceId  :0,
			smartGatewayId  :0,
			pointGrId  :0,			
			monitorId  :0,
			announceId  :0,
			fontSize1	:48,
			fontSize2   :32,
			fontSize3   :30,
			fontSize4   :34,
			fontSize5   :34,
			fontSize6   :16,
			color1      :'#000000',
			color2      :'#000000',
			color3      :'#000000',
			color4      :'#000000',
			color5      :'#000000',
			color6      :'#000000',
			placeName   :undefined,
			sologon     :'试验塔井道管理',
			alarmText   :'样梯试验中，禁止乘坐',
			memo        :'使用井道请向技术研发总部-部件研发部提出申请-《试验场地申请流程》，谢谢！',
			dateFrom    :undefined,
			dateTo      :undefined,
			clmn1Title  :'样梯型号',
			clmn1Value  :undefined,
			clmn2Title  :'使用日期',
			clmn2Value  :undefined,
			clmn3Title  :'试验项目',
			clmn3Value  :undefined,
			clmn4Title  :'负责人',
			clmn4Value  :undefined,
			clmn5Title  :'使用部门',
			clmn5Value  :undefined,
			clmn6Title  :'联系电话',
			clmn6Value  :undefined,
			date:[moment(),moment()],
			now			:undefined,
			
		},
        clr1:{'color': '#000000','font-size': '48px'} ,
        clr2:{'color': '#000000','font-size': '32px'} ,
        clr3:{'color': '#000000','font-size': '30px'} ,
        clr4:{'color': '#000000','font-size': '34px'} ,
        clr5:{'color': '#000000','font-size': '34px'} ,
		clr6:{'color': '#000000','font-size': '16px'} ,
		vail:{
			pln:{
				cls:'pln',
				title:'',
				visible:false,
				err:0,
			},
			slg:{
				cls:'slg',
				title:'',
				visible:false,
				err:0,
			},
			alm:{
				cls:'alm',
				title:'',
				visible:false,
				err:0,
			},
			mem:{
				cls:'mem',
				title:'',
				visible:false,
				err:0,
			},
			clmk1:{
				cls:'clmk1',
				title:'',
				visible:false,
				err:0,
			},
			clmv1:{
				cls:'clmv1',
				title:'',
				visible:false,
				err:0,
			},
			clmk3:{
				cls:'clmk3',
				title:'',
				visible:false,
				err:0,
			},
			clmv3:{
				cls:'clmv3',
				title:'',
				visible:false,
				err:0,
			},
			clmk2:{
				cls:'clmk2',
				title:'',
				visible:false,
				err:0,
			},
			clmv2:{
				cls:'clmv2',
				title:'',
				visible:false,
				err:0,
			},
			clmk4:{
				cls:'clmk4',
				title:'',
				visible:false,
				err:0,
			},
			clmv4:{
				cls:'clmv4',
				title:'',
				visible:false,
				err:0,
			},
			clmk5:{
				cls:'clmk5',
				title:'',
				visible:false,
				err:0,
			},
			clmv5:{
				cls:'clmv5',
				title:'',
				visible:false,
				err:0,
			},
			clmk6:{
				cls:'clmk6',
				title:'',
				visible:false,
				err:0,
			},
			clmv6:{
				cls:'clmv6',
				title:'',
				visible:false,
				err:0,
			},
			color1:{ cls:'color1', title:'', visible:false, err:0, },
			color2:{ cls:'color2', title:'', visible:false, err:0, },
			color3:{ cls:'color3', title:'', visible:false, err:0, },
			color4:{ cls:'color4', title:'', visible:false, err:0, },
			color5:{ cls:'color5', title:'', visible:false, err:0, },
			color6:{ cls:'color6', title:'', visible:false, err:0, },
			rule:{
				pln: [
					{type:1, required: true, message: this.$t('energy.enedata415') },
					{type:2, min: 1, max: 30, message: this.$t('energy.enedata421') },
					{type:3, message: this.$t('energy.enedata218'), },
				],
				clmnTitle: [
					{type:1, required: true, message: this.$t('energy.enedata417') },
					{type:2, min: 1, max: 30, message: this.$t('energy.enedata423') },
					{type:3, message: this.$t('energy.enedata218'), },
				],
				clmnValue: [
					{type:1, required: true, message: this.$t('energy.enedata418') },
					{type:2, min: 1, max: 30, message: this.$t('energy.enedata424') },
					{type:3, message: this.$t('energy.enedata218'), },
				],
				date: [
					{type:4, message: this.$t('energy.enedata430'), },
				],
				slg: [
					{type:1, required: true, message: this.$t('energy.enedata416') },
					{type:2, min: 1, max: 30, message: this.$t('energy.enedata422') },
					{type:3, message: this.$t('energy.enedata218'), },
				],
				alm: [
					{type:1, required: true, message: this.$t('energy.enedata419') },
					{type:2, min: 1, max: 30, message: this.$t('energy.enedata425') },
					{type:3, message: this.$t('energy.enedata218'), },
				],
				mem: [
					{type:1, required: true, message: this.$t('energy.enedata420') },
					{type:2, min: 1, max: 100, message: this.$t('energy.enedata426') },
					{type:3, message: this.$t('energy.enedata218'), },
				],
				color:[
					{type:1, required: true, message: this.$t('energy.enedata432') },
					{type:5, message: this.$t('energy.enedata433'), },
				]
			}
		}
    };
  }, 
  mounted(){

  },
  destroyed() {
	
  },
  methods: {
	 getAnnouncementInfo(){
		 let param ={
			 equipmentId:this.equipmentId,
			 id:this.id
		 }
		this.loading = true;
        getEnergyDeviceAnnouncementInfo(param)
            .then((res) => {
			console.log("GetEnergyDeviceAnnouncementInfo res",res);
			if (res.errorCode == "00") {
				this.setAnnouncementInfo(res.data);
			}else{
				this.$message.error(res.msg);
				this.disable = true;
			}
            this.loading = false;
        }).catch((err) => {
            console.log(err);
            this.loading = false;
        });
	 },
	 saveAnnouncementInfo(action){
		let param ={
			action			:action,
			clientId		:this.$store.getters.clientId,
            sitegrId		:this.$store.getters.sitegrId,
            siteId			:0,
			pointGrId		:this.form.pointGrId,
			monitorId		:this.form.monitorId,
			smartGatewayId	:this.form.smartGatewayId,
			onlySave		:this.onlySave==0,
			announcement:{
				announceId		: this.form.announceId	,
				placeName		: this.form.placeName	,
				sologon			: this.form.sologon		,
				displayMode		: this.form.displayMode	,
				alarm			: this.form.alarmText		,
				memo			: this.form.memo		,
				from			: this.form.date[0].format('YYYY/MM/DD HH:mm:ss'),
				to				: this.form.date[1].format('YYYY/MM/DD HH:mm:ss'),
				columnName1		: this.form.clmn1Title,
				columnName2		: this.form.clmn2Title,
				columnName3		: this.form.clmn3Title,
				columnName4		: this.form.clmn4Title,
				columnName5		: this.form.clmn5Title,
				columnName6		: this.form.clmn6Title,
				columnValue1	: this.form.clmn1Value,
				columnValue2	: this.form.clmn2Value,
				columnValue3	: this.form.clmn3Value,
				columnValue4	: this.form.clmn4Value,
				columnValue5	: this.form.clmn5Value,
				columnValue6	: this.form.clmn6Value,
				fontColor1		: this.form.color1,
				fontColor2		: this.form.color2,
				fontColor3		: this.form.color3,
				fontColor4		: this.form.color4,
				fontColor5		: this.form.color5,
				fontColor6		: this.form.color6,
				fontSize1		: this.form.fontSize1,
				fontSize2		: this.form.fontSize2,
				fontSize3		: this.form.fontSize3,
				fontSize4		: this.form.fontSize4,
				fontSize5		: this.form.fontSize5,
				fontSize6		: this.form.fontSize6,
				
			}
		}
		console.log('SaveAnnouncementInfo Param',param);
		this.$confirm({
			title:this.$t("energy.enedata184"),
			centered: true,
			onOk: () => {
				this.loading = true;
				saveAnnouncementInfo(param) 
					.then((res) => {
						console.log("Save Announcement Info RES",res);
						if (res.errorCode == "00") {
							this.$message.success(res.msg);
							this.setAnnouncementInfo(res.data.announcement);
						} else {
							this.$message.error(res.msg);
						}
						this.loading = false;
					}).catch((err) => {
						this.$message.error("设置失败。");
						console.log(err);
						this.loading = false;
					});
				}
          });
	 },
	 setAnnouncementInfo(info){
		this.$parent.announceId 	= info.announceId  		;
		this.form.announceId  		= info.announceId  		;
		this.form.smartGatewayId  	= info.smartGatewayId   ;
		this.form.pointGrId  		= info.pointGrId  	    ;
		this.form.monitorId  		= info.monitorId  	    ;
		this.form.placeName  		= info.placeName  	    ;
		this.form.sologon    		= info.sologon    	    ;
		this.form.alarmText  		= info.alarm	  	    ;
		this.form.memo       		= info.memo       	    ;
		this.form.dateFrom   		= info.dateFrom   	    ;
		this.form.dateTo     		= info.dateTo     	    ;
		this.form.clmn1Title 		= info.columnName1 	    ;
		this.form.clmn1Value 		= info.columnValue1	    ;
		this.form.clmn2Title 		= info.columnName2 	    ;
		this.form.clmn2Value 		= info.columnValue2 	;
		this.form.clmn3Title 		= info.columnName3 	    ;
		this.form.clmn3Value 		= info.columnValue3 	;
		this.form.clmn4Title 		= info.columnName4 	    ;
		this.form.clmn4Value 		= info.columnValue4 	;
		this.form.clmn5Title 		= info.columnName5 	    ;
		this.form.clmn5Value 		= info.columnValue5 	;
		this.form.clmn6Title 		= info.columnName6 	    ;
		this.form.clmn6Value 		= info.columnValue6 	;
		let from = info.from?new moment(info.from):new moment();
		let to = info.from?new moment(info.to):new moment();
		this.form.date= [from,to];

		if(info.fontSize1)
			this.form.fontSize1	  = parseInt(info.fontSize1);
		if(info.fontSize2)
			this.form.fontSize2   = parseInt(info.fontSize2)   ;
		if(info.fontSize3)
			this.form.fontSize3   = parseInt(info.fontSize3)   ;
		if(info.fontSize4)
			this.form.fontSize4   = parseInt(info.fontSize4)   ;
		if(info.fontSize5)
			this.form.fontSize5   = parseInt(info.fontSize5)   ;
		if(info.fontSize6)
			this.form.fontSize6   = parseInt(info.fontSize6)   ;

		if(info.fontColor1)
			this.form.color1      = info.fontColor1  ;
		if(info.fontColor2)
			this.form.color2      = info.fontColor2  ;
		if(info.fontColor3)
			this.form.color3      = info.fontColor3  ;
		if(info.fontColor4)
			this.form.color4      = info.fontColor4  ;
		if(info.fontColor5)
			this.form.color5      = info.fontColor5  ;
		if(info.fontColor6)
			this.form.color6      = info.fontColor6  ;

		this.clr1= {'color': this.form.color1,'font-size': this.form.fontSize1+'px'} ;
		this.clr2= {'color': this.form.color2,'font-size': this.form.fontSize2+'px'} ;
		this.clr3= {'color': this.form.color3,'font-size': this.form.fontSize3+'px'} ;
		this.clr4= {'color': this.form.color4,'font-size': this.form.fontSize4+'px'} ;
		this.clr5= {'color': this.form.color5,'font-size': this.form.fontSize5+'px'} ;
		this.clr6= {'color': this.form.color6,'font-size': this.form.fontSize6+'px'} ;
	 },
     resetForm(){
        // if(this.$refs.issueAnnounceForm){
		// 	this.$refs.issueAnnounceForm.resetFields();
			this.form.announceId  =  undefined;
			this.form.smartGatewayId  =  undefined;
			this.form.pointGrId  =  undefined;
			this.form.monitorId  =  undefined;
			this.form.placeName  =  undefined;
			this.form.sologon    = '试验塔井道管理';
			this.form.alarmText  = '样梯试验中，禁止乘坐';
			this.form.memo       = '使用井道请向技术研发总部-部件研发部提出申请-《试验场地申请流程》，谢谢！';
			this.form.dateFrom   = undefined;
			this.form.dateTo     = undefined;
			this.form.clmn1Title = '样梯型号';
			this.form.clmn1Value = undefined;
			this.form.clmn2Title = '使用日期';
			this.form.clmn2Value = undefined;
			this.form.clmn3Title = '试验项目';
			this.form.clmn3Value = undefined;
			this.form.clmn4Title = '负责人';
			this.form.clmn4Value = undefined;
			this.form.clmn5Title = '使用部门';
			this.form.clmn5Value = undefined;
			this.form.clmn6Title = '联系电话';
			this.form.clmn6Value = undefined;
			this.form.date= [moment(),moment()];

			this.form.fontSize1	  = 48,
			this.form.fontSize2   = 32,
			this.form.fontSize3   = 30,
			this.form.fontSize4   = 34,
			this.form.fontSize5   = 34,
			this.form.fontSize6   = 16,
			this.form.color1      = '#000000',
			this.form.color2      = '#000000',
			this.form.color3      = '#000000',
			this.form.color4      = '#000000',
			this.form.color5      = '#000000',
			this.form.color6      = '#000000',
			
			this.clr1= {'color': '#000000','font-size': '48px'} ;
			this.clr2= {'color': '#000000','font-size': '32px'} ;
			this.clr3= {'color': '#000000','font-size': '30px'} ;
			this.clr4= {'color': '#000000','font-size': '34px'} ;
			this.clr5= {'color': '#000000','font-size': '34px'} ;
			this.clr6= {'color': '#000000','font-size': '16px'} ;
			this.form.now = moment().format('dddd YYYY-MM-DD HH:mm:ss');

			this.vail.pln={ cls:'pln', title:'', visible:false, err:0, };
			this.vail.slg={ cls:'slg', title:'', visible:false, err:0, };
			this.vail.alm={ cls:'alm', title:'', visible:false, err:0, };
			this.vail.mem={ cls:'mem', title:'', visible:false, err:0, };
			this.vail.clmk1={ cls:'clmk1', title:'', visible:false, err:0, };
			this.vail.clmk2={ cls:'clmk2', title:'', visible:false, err:0, };
			this.vail.clmk3={ cls:'clmk3', title:'', visible:false, err:0, };
			this.vail.clmk4={ cls:'clmk4', title:'', visible:false, err:0, };
			this.vail.clmk5={ cls:'clmk5', title:'', visible:false, err:0, };
			this.vail.clmk6={ cls:'clmk6', title:'', visible:false, err:0, };
			this.vail.clmv1={ cls:'clmv1', title:'', visible:false, err:0, };
			this.vail.clmv2={ cls:'clmv2', title:'', visible:false, err:0, };
			this.vail.clmv3={ cls:'clmv3', title:'', visible:false, err:0, };
			this.vail.clmv4={ cls:'clmv4', title:'', visible:false, err:0, };
			this.vail.clmv5={ cls:'clmv5', title:'', visible:false, err:0, };
			this.vail.clmv6={ cls:'clmv6', title:'', visible:false, err:0, };
			this.vail.color1={ cls:'color1', title:'', visible:false, err:0, };
			this.vail.color2={ cls:'color2', title:'', visible:false, err:0, };
			this.vail.color3={ cls:'color3', title:'', visible:false, err:0, };
			this.vail.color4={ cls:'color4', title:'', visible:false, err:0, };
			this.vail.color5={ cls:'color5', title:'', visible:false, err:0, };
			this.vail.color6={ cls:'color6', title:'', visible:false, err:0, };

			this.disable = false;
			this.onlySave = 1;
			this.mode = 4;
        // }
	 },
	 changeFontSize(idx){
		 console.log('changeFontSize',idx,this.form['fontSize'+idx]);
		 this.changeStyle(idx,'font-size',this.form['fontSize'+idx]+'px');
	 },
	 changeColor(idx){
		 console.log('changeColor',idx,this.form['color'+idx]);
		 this.changeStyle(idx,'color',this.form['color'+idx]);
	 },
	 changeStyle(idx,key,val){
		 switch(idx){
			 case 1:
				 this.clr1[key]=val;
			 break;
			 case 2:
				 this.clr2[key]=val;
			 break;
			 case 3:
				this.clr3[key]=val;
			 break;
			 case 4:
				 this.clr4[key]=val;
			 break;
			 case 5:
				 this.clr5[key]=val;
			 break;
			 case 6:
				 this.clr6[key]=val;
			 break;
			 default:

			 break;
		 }
	 },
	 handleOk(mode){
		 let vaild = false;
		 if(mode==1 || mode==4){
			vaild = this.validate();
		 }else if(mode==2){
			vaild = this.validateColor();
		 }else if(mode==4){
			vaild = this.validate();
			if(vaild)
				vaild = this.validateColor();
		 }
		if(!vaild){
			this.saveAnnouncementInfo(mode);
		}
	 },
	 validateColor(){
		let err = false;
		this.vail.color1 = this.check('color1',this.form.color1,this.vail.rule.color);
		if(!err && this.vail.color1.err>0)
			 err = true;

		this.vail.color2 = this.check('color2',this.form.color2,this.vail.rule.color);
		 if(!err && this.vail.color2.err>0)
			 err = true;

		this.vail.color3 = this.check('color3',this.form.color3,this.vail.rule.color);
		 if(!err && this.vail.color3.err>0)
			 err = true;

		this.vail.color4 = this.check('color4',this.form.color4,this.vail.rule.color);
		if(!err && this.vail.color4.err>0)
			 err = true;

		this.vail.color5 = this.check('color5',this.form.color5,this.vail.rule.color);
		 if(!err && this.vail.color5.err>0)
			 err = true;

		this.vail.color6 = this.check('color6',this.form.color6,this.vail.rule.color);
		 if(!err && this.vail.color5.err>0)
			 err = true;

	 },
	 validate(){
		 let err = false;
		 this.vail.pln = this.check('pln',this.form.placeName,this.vail.rule.pln);
		 if(!err && this.vail.pln.err>0)
			 err = true;
		
		 this.vail.slg = this.check('slg',this.form.sologon,this.vail.rule.slg);
		 if(!err && this.vail.slg.err>0)
			 err = true;
		
		 this.vail.alm = this.check('alm',this.form.alarmText,this.vail.rule.alm);
		 if(!err && this.vail.alm.err>0)
			 err = true;

		 this.vail.mem = this.check('mem',this.form.memo,this.vail.rule.mem);
		 if(!err && this.vail.mem.err>0)
			 err = true;

		this.vail.clmk1 = this.check('clmk1',this.form.clmn1Title,this.vail.rule.clmnTitle);
		 if(!err && this.vail.clmk1.err>0)
			 err = true;

		this.vail.clmv1 = this.check('clmv1',this.form.clmn1Value,this.vail.rule.clmnValue);
		 if(!err && this.vail.clmv1.err>0)
			 err = true;

		this.vail.clmk2 = this.check('clmk2',this.form.clmn2Title,this.vail.rule.clmnTitle);
		if(!err && this.vail.clmk2.err>0)
			err = true;

		this.vail.clmv2 = this.check('clmv2',this.form.date,this.vail.rule.date);
		if(!err && this.vail.clmv2.err>0)
			err = true;

		this.vail.clmk3 = this.check('clmk3',this.form.clmn3Title,this.vail.rule.clmnTitle);
		if(!err && this.vail.clmk3.err>0)
			err = true;

		this.vail.clmv3 = this.check('clmv3',this.form.clmn3Value,this.vail.rule.clmnValue);
		if(!err && this.vail.clmv3.err>0)
			err = true;

		this.vail.clmk4 = this.check('clmk4',this.form.clmn4Title,this.vail.rule.clmnTitle);
		if(!err && this.vail.clmk4.err>0)
			err = true;

		this.vail.clmv4 = this.check('clmv4',this.form.clmn4Value,this.vail.rule.clmnValue);
		if(!err && this.vail.clmv4.err>0)
			err = true;

			this.vail.clmk5 = this.check('clmk5',this.form.clmn5Title,this.vail.rule.clmnTitle);
		if(!err && this.vail.clmk5.err>0)
			err = true;

		this.vail.clmv5 = this.check('clmv5',this.form.clmn5Value,this.vail.rule.clmnValue);
		if(!err && this.vail.clmv5.err>0)
			err = true;

			this.vail.clmk6 = this.check('clmk6',this.form.clmn6Title,this.vail.rule.clmnTitle);
		if(!err && this.vail.clmk6.err>0)
			err = true;

		this.vail.clmv6 = this.check('clmv6',this.form.clmn6Value,this.vail.rule.clmnValue);
		if(!err && this.vail.clmv6.err>0)
			err = true;

		 return err;
	 },
	 check(tag,value,rules){
		 let err = 0;
		 let msg = '';
		 for (let i = 0; i < rules.length; i++) {
			 let rule = rules[i];
			 if(rule.type==1 && rule.required){
				 if(value===undefined || value===''){
					err = 1;
					msg = rule.message;
					break;
				 }
			 }
			 if(rule.type==2 ){
				let len = value.length;
				if(len<rule.min || len>rule.max){
					console.log('len>>>>'+len);
					err = 1;
					msg = rule.message;
					break;
				}
			 }

			 if(rule.type==3 ){
				let bol = this.checkString(value);
				if(bol){
					err = 1;
					msg = rule.message;
					break;
				}
			 }

			 if(rule.type==4 ){
				
				if(value.length!=2 || value[0]==undefined || value[1]==undefined){
					err = 1;
					msg = rule.message;
					break;
				}
			 }

			 if(rule.type==5 ){

			 }
		 }

		 
		 let visible = err>0;
		 let cls = visible?'err':'';
		 let verr = {
			cls:tag + ' ' + cls,
			title:msg,
			visible:visible,
			err:err,
		 }

		 return verr;
	 },
	 checkString(value){
        let regEn = /[`~!@#$%^&*+<>?:"{},\/;'[\]]/im;
        return regEn.test(value) ;
	 },
	 handleCancel(){
		 this.visible = false;
	 },
  },
  
  watch:{
    "$parent.issueAnnounceDialogVisible":{
      handler(val){
           console.log("visible",val);
          if(val){              
            this.resetForm();
			this.visible = val;
			this.getAnnouncementInfo();
          }
      },
      deep:true,
      immediate:true,
    },
    "visible":{
        handler(val){
            console.log("visible",val);
            if(!val){
				this.$parent.issueAnnounceDialogVisible =  val;
				this.vail.pln={ cls:'pln', title:'', visible:false, err:0, };
				this.vail.slg={ cls:'slg', title:'', visible:false, err:0, };
				this.vail.alm={ cls:'alm', title:'', visible:false, err:0, };
				this.vail.mem={ cls:'mem', title:'', visible:false, err:0, };
				this.vail.clmk1={ cls:'clmk1', title:'', visible:false, err:0, };
				this.vail.clmk2={ cls:'clmk2', title:'', visible:false, err:0, };
				this.vail.clmk3={ cls:'clmk3', title:'', visible:false, err:0, };
				this.vail.clmk4={ cls:'clmk4', title:'', visible:false, err:0, };
				this.vail.clmk5={ cls:'clmk5', title:'', visible:false, err:0, };
				this.vail.clmk6={ cls:'clmk6', title:'', visible:false, err:0, };
				this.vail.clmv1={ cls:'clmv1', title:'', visible:false, err:0, };
				this.vail.clmv2={ cls:'clmv2', title:'', visible:false, err:0, };
				this.vail.clmv3={ cls:'clmv3', title:'', visible:false, err:0, };
				this.vail.clmv4={ cls:'clmv4', title:'', visible:false, err:0, };
				this.vail.clmv5={ cls:'clmv5', title:'', visible:false, err:0, };
				this.vail.clmv6={ cls:'clmv6', title:'', visible:false, err:0, };
            }
        },
        deep:true,
        immediate:true,
    },
  },
  components: {
  },
};
</script>
<style scoped>
#issueAnnounceDialog{
    height: 100%;
    width:100%;
    position: relative;  
}
 div /deep/.ant-modal{
     top:10px;
 }
 div /deep/.ant-modal-body .el-loading-mask {
    position: absolute;
    z-index: 2000;
    background-color: rgba(255,255,255,.9);
    margin: 0;
    top: -60px;
    right: -10px;
    bottom: -60px;
    left: -10px;
    transition: opacity .3s;
}
div /deep/.ant-modal-body{padding: 5px;}
div /deep/.ant-calendar-picker-input.ant-input{
	background-color: transparent;
	height:40px; 
	border: 0;
}
/* .base-box{
    border: 1px solid #f5f5f5;
    border-radius: 3px;
    padding: 3px;
    margin-bottom: 2px;
} */
.form-dis-item{margin-right:3px ;}
.bgm{
		width:1024px;
		height:768px; 
		background-image:url(../../../public/images/announcement/annbg02.jpg);
		background-repeat:no-repeat;
		margin: 2px;
		padding: 2px;
		position: relative; 
	}
	.logo{
		position: absolute;
		top: 16px;
		left:33px;
		width:166px;
		height:56px; 
		background-repeat:no-repeat;
	}
	.pln{
		position: absolute;
		top: 30px;
		left:200px;
		width:540px;
		height: 70px;  
		border:1px solid #FFFFFF;
		font-weight: bold;
	}
	.slg{
		position: absolute;
		top: 24px;
		left:749px;
		width:240px;
		height: 40px;
		border:1px solid #FFFFFF;
		padding:5px 0;
	}
	.alm{
		position: absolute;
		top: 643px;
		left:283px;
		width:458px;
		height: 62px; 
	}
	.mem{
		position: absolute;
		top: 720px;
		left:295px;
		width:720px;
		height: 30px; 
		border:1px solid #FFFFFF;
	}
	.date{
		position: absolute;
		top: 720px;
		left:21px;
		width:280px;
		height: 30px; 
	}
	.clmk1{
		position: absolute;
		top: 136px;
		left:164px;
		width:218px;
		height: 64px; 
	}
	.clmv1{
		position: absolute;
		top: 216px;
		left:164px;
		width:320px;
		height: 40px;
		border:1px solid #FFFFFF;
		padding:5px 0;
	}
	.clmk2{
		position: absolute;
		top: 136px;
		left:648px;
		width:218px;
		height: 64px; 
	}
	.clmv2{
		position: absolute;
		top: 216px;
		left:648px;
		height: 40px;
		background-color: transparent; 
		border:1px solid #FFFFFF;
		width:360px;
	}
	
	.clmk3{
		position: absolute;
		top: 315px;
		left:164px;
		width:218px;
		height: 64px;  
	}
	.clmv3{
		position: absolute;
		top: 395px;
		left:164px;
		width:320px;
		height: 40px;
		border:1px solid #FFFFFF;
		padding:5px 0;
	}
	.clmk4{
		position: absolute;
		top: 315px;
		left:648px;
		width:218px;
		height: 64px; 
	}
	.clmv4{
		position: absolute;
		top: 395px;
		left:648px;
		width:320px;
		height: 40px;
		border:1px solid #FFFFFF;
		padding:5px 0;
	}
	
	.clmk5{
		position: absolute;
		top: 494px;
		left:164px;
		width:218px;
		height: 64px;   
	}
	.clmv5{
		position: absolute;
		top: 574px;
		left:164px;
		width:320px;
		height: 40px;
		border:1px solid #FFFFFF;
		padding:5px 0;
	}
	.clmk6{
		position: absolute;
		top: 494px;
		left:648px;
		width:218px;
		height: 64px;   
		margin:5px 0;
		padding:5px 0;
	}
	.clmv6{
		position: absolute;
		top: 574px;
		left:648px;
		width:320px;
		height: 40px;
		border:1px solid #FFFFFF;
		padding:5px 0;
	}
	
	input.txt{
		height: 100%;
		width: 100%;
		text-align: center;
		vertical-align: middle;
		border: 0;
		background-color: transparent;
	}
	input.txt1{
		height: 100%;
		width: 100%;
		text-align: center	;
		vertical-align: middle;
		background-image:url(../../../public/images/announcement/bottom3.png);
		background-repeat:no-repeat;
		background-color: transparent;
		border:0;
	}
	/* padding: 15px; padding: 6px;*/
	input.txt4{
		height: 100%;
		width: 100%;
		text-align: center	;
		vertical-align: middle;
		background-image:url(../../../public/images/announcement/bottom4.png);
		background-repeat:no-repeat;
		background-color: transparent;
		border:0;
	}
	input.txt2{
		height: 100%;
		width: 100%;
		background-color: transparent;
		border: 0;
	}
	input.txt5{
		width: 100%;
		background-color: transparent;
		border: 0;
	}
	input.txt3{
		width: 165px;
        height: 100%;
		background-color: transparent;
		border: 0;
	}
	.set{
		margin: 5px 0;
		padding: 5px 0;
	}
	.set1{
		border-bottom: 1px solid #cccccc;
		margin: 5px 0;
		padding: 5px 0;
	}
	.set2{
		margin-left: 10px;
		margin-top: 3px;
	}
	.sq{
		margin-bottom: 1px;
	}
	.err{
		border:1px solid #ff0000;
	}
	div /deep/.ant-tooltip-arrow::before{
		background-color: rgba(255, 0, 0, 0.75);
	}
	div /deep/.ant-tooltip-inner{
		padding: 4px 6px;
		color: #f00;
		background-color: rgba(255, 255, 255, 0.75);
		border: 1px solid #ff0000;
	}
</style>